import { NgModule } from '@angular/core';
import { GrexScrollbarDirective } from '@grex/directives/scrollbar/scrollbar.directive';

@NgModule({
    declarations: [
        GrexScrollbarDirective
    ],
    exports     : [
        GrexScrollbarDirective
    ]
})
export class GrexScrollbarModule
{
}
