import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrexCardComponent } from '@grex/components/card/card.component';

@NgModule({
    declarations: [
        GrexCardComponent
    ],
    imports     : [
        CommonModule
    ],
    exports     : [
        GrexCardComponent
    ]
})
export class GrexCardModule
{
}
