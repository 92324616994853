import { Injectable } from '@angular/core';
import { FileParameter, GeneralClient, NewsModel, PagingNewsModel } from 'app/app-clients/general-client';
import { AttachmentTypeConst } from 'app/core/consts/attachment-type-const';
import { ImageCategoryConst } from 'app/core/consts/image-category-const';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PhotoService {
    constructor(private _generalClient: GeneralClient) {}

    UploadSinglePhoto(
        file: FileParameter | null | undefined,
        attachmentType: AttachmentTypeConst | null | undefined, // photo, video, excel, audio
        imageCategory: ImageCategoryConst | null | undefined // property, news, user, banner
    ) {
        
        return this._generalClient.apiPhotoSinglePost(file, attachmentType, undefined, imageCategory)
            .pipe(
                map(response => {
                    return response;
                }),
                catchError((err: any) => {
                    return err;
                })
            );
    }

    UploadMultiPhoto(
        file: FileParameter[] | null | undefined,
        attachmentType: AttachmentTypeConst | null | undefined, // photo, video, excel, audio
        imageCategory: ImageCategoryConst | null | undefined // property, news, user, banner
    ) {
        
        return this._generalClient.apiPhotoMultiplePost(file, attachmentType, undefined, imageCategory)
            .pipe(
                map(response => {
                    return response;
                }),
                catchError((err: any) => {
                    return err;
                })
            );
    }
}
