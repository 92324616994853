<!-- Navigation -->
<grex-vertical-navigation
    class="dark bg-gray-900"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="data.navigation.default"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container grexVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center h-20 p-6 pb-0">
            <img
                class="w-64"
                src="assets/images/logo/Logo2-White-Green.png"
                alt="Logo image">
        </div>
    </ng-container>
    <ng-container grexVerticalNavigationContentFooter>
        <!-- Logo -->
        <div class="flex items-center h-20 p-6 pb-0">
            <div class="items-center justify-start w-full h-14 border-t py-4 bg-card dark:bg-transparent">
                <span class="font-medium text-secondary">Grex &copy; {{currentYear}}</span>
            </div>
        </div>
    </ng-container>
</grex-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent">
        <!-- Navigation toggle button -->
        <button class="flex"
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Page Title -->
        <h2 class="items-center pl-2 space-x-2 font-bold text-lg">{{pageTitle}}</h2>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-2">
            <!-- <search [appearance]="'bar'"></search> -->
            <!-- <shortcuts [shortcuts]="data.shortcuts"></shortcuts> -->
            <!-- <messages [messages]="data.messages"></messages> -->
            <!-- <notifications [notifications]="data.notifications"></notifications> -->
            <user-menu></user-menu>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent">
        <span class="font-medium text-secondary">Grex &copy; {{currentYear}}</span>
    </div> -->

</div>
