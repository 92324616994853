import { RegisterModel, Result } from './../app-clients/general-client';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeneralClient, IdentityResult, LoginUserModel, RefreshTokenModel, UserModel } from 'app/app-clients/general-client';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { SharedService } from './shared.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private baseUrl = environment.baseUrl

  private _identity: BehaviorSubject<IdentityResult | null> = new BehaviorSubject<IdentityResult | null>(null);

  constructor(private _generalClient: GeneralClient, private _httpClient: HttpClient, private _sharedService: SharedService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: IdentityResult)
  {
      // Store the value
      this._identity.next(value);
  }

  get user$(): Observable<{} | null> {
    return this._identity.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * login
   *
   * @param user
  */
  login(req: LoginUserModel) {
    return this._generalClient.apiUserLogin(req);
  }

  /**
   * register
   *
   * @param user
  */
  // register(req: RegisterRequest) {
  //   return this.client.apiUserRegister(req);
  // }

  /**
   * logout
   *
   * @param user
  */
  logout() {
    return this._generalClient.apiUserLogout();
  }

  /**
   * userInfo
   *
   * @param user
  */
  userInfo(): Observable<IdentityResult> {
    return this._httpClient.get<IdentityResult>(this.baseUrl + '/api/user/info', 
    {
        headers: this._sharedService.setHeaderAccessToken()
    })
  }

  refreshToken(req: RefreshTokenModel) {
    return this._generalClient.apiUserRefreshToken(req);
  }

  register(model: RegisterModel) {
    return this._generalClient.apiUserRegister(model)
      .pipe(
        map(response => {
          return response;
        }),
        catchError((err: any) => {
          return err;
        })
      );
  }

  deleteUser(sellerId: string | undefined): Observable<Result>
  {
    return this._generalClient.apiUserDelete(sellerId)
      .pipe(
        map(response => {
          return response;
        }),
        catchError((err: any) => {
          return err;
        })
      );
  }
}
