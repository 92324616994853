/* tslint:disable:max-line-length */
import { GrexNavigationItem } from '@grex/components/navigation';

export const defaultNavigation: GrexNavigationItem[] = [
    
    // Common Config
    {
        id      : 'commonConfig',
        title   : 'Common Config',
        subtitle: 'Common Config Management',
        type    : 'group',
        icon    : 'heroicons_outline:cog',
        children: [
            // Time For Post
            {
                id      : 'timeForPost',
                title   : 'Time For Post',
                type    : 'basic',
                icon    : 'heroicons_outline:calendar',
                link    : '/time-for-post'
            },
            // Social Network
            {
                id      : 'socialNetwork',
                title   : 'Social Network',
                type    : 'basic',
                icon    : 'heroicons_outline:view-grid',
                link    : '/social-network'
            },
            // Banner
            {
                id      : 'banner',
                title   : 'Banner',
                type    : 'basic',
                icon    : 'heroicons_outline:photograph',
                link    : '/banner'
            },
            // Common Config
            {
                id      : 'common-config',
                title   : 'Common Config',
                type    : 'basic',
                icon    : 'heroicons_outline:cog',
                link    : '/common-config'
            },
        ]
    },
    // Project
    {
        id      : 'project',
        title   : 'Project',
        subtitle: 'Project Management',
        type    : 'group',
        icon    : 'heroicons_outline:table',
        children: [
            {
                id      : 'project',
                title   : 'Exclusive Project',
                type    : 'basic',
                icon    : 'heroicons_outline:table',
                link    : '/project'
            }
        ]
    },
    // Property
    {
        id      : 'property',
        title   : 'Property',
        subtitle: 'Property Management',
        type    : 'group',
        icon    : 'heroicons_outline:office-building',
        children: [
            {   
                id      : 'property.property',
                title   : 'Property Management',
                type    : 'basic',
                icon    : 'heroicons_outline:office-building',
                link    : '/property/property'
            },
            // {   
            //     id      : 'property.commercial',
            //     title   : 'Commercial Property',
            //     type    : 'basic',
            //     icon    : 'heroicons_outline:office-building',
            //     link    : '/property/commercial'
            // },
        ]
    },
    // Seller
    {
        id      : 'user',
        title   : 'User',
        subtitle: 'User Management',
        type    : 'group',
        icon    : 'heroicons_outline:user',
        children: [
            {   
                id      : 'user.seller',
                title   : 'Seller',
                type    : 'basic',
                icon    : 'heroicons_outline:user',
                link    : '/user/seller'
            },
            {   
                id      : 'user.guest',
                title   : 'Guest',
                type    : 'basic',
                icon    : 'heroicons_outline:user',
                link    : '/user/guest'
            },
        ]
    },
    // News
    {
        id      : 'news',
        title   : 'News',
        subtitle: 'News Management',
        type    : 'group',
        icon    : 'heroicons_outline:newspaper',
        children: [
            {   
                id      : 'news.news-category',
                title   : 'News Category',
                type    : 'basic',
                icon    : 'heroicons_outline:duplicate',
                link    : '/news/news-category'
            },
            {   
                id      : 'news.news',
                title   : 'News',
                type    : 'basic',
                icon    : 'heroicons_outline:newspaper',
                link    : '/news/news'
            }
        ]
    },
    // Contact
    {
        id      : 'contact',
        title   : 'Contact',
        subtitle: 'Contact Management',
        type    : 'group',
        icon    : 'heroicons_outline:mail',
        children: [
            {
                id      : 'contact',
                title   : 'Contact',
                type    : 'basic',
                icon    : 'heroicons_outline:mail',
                link    : '/contact'
            },
        ]
    },
    //promotion
    {
        id      : 'promotion',
        title   : 'Promotion',
        subtitle: 'Promotion Management',
        type    : 'group',
        icon    : 'heroicons_outline:bell',
        children: [
            {   
                id      : 'promotion.notification',
                title   : 'Notification',
                type    : 'basic',
                icon    : 'heroicons_outline:bell',
                link    : '/promotion/notification'
            }
        ]
    }
];
export const compactNavigation: GrexNavigationItem[] = [
    {
        id      : 'pages',
        title   : 'Pages',
        type    : 'aside',
        icon    : 'heroicons_outline:document-duplicate',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'user-interface',
        title   : 'UI',
        type    : 'aside',
        icon    : 'heroicons_outline:collection',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'navigation-features',
        title   : 'Navigation',
        type    : 'aside',
        icon    : 'heroicons_outline:menu',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
export const futuristicNavigation: GrexNavigationItem[] = [
    {
        id   : 'others',
        title: 'OTHERS',
        type : 'group'
    },
    {
        id      : 'pages',
        title   : 'Pages',
        type    : 'aside',
        icon    : 'heroicons_outline:document-duplicate',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'user-interface',
        title   : 'User Interface',
        type    : 'aside',
        icon    : 'heroicons_outline:collection',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'navigation-features',
        title   : 'Navigation Features',
        type    : 'aside',
        icon    : 'heroicons_outline:menu',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
export const horizontalNavigation: GrexNavigationItem[] = [
    {
        id      : 'pages',
        title   : 'Pages',
        type    : 'group',
        icon    : 'heroicons_outline:document-duplicate',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'user-interface',
        title   : 'UI',
        type    : 'group',
        icon    : 'heroicons_outline:collection',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'navigation-features',
        title   : 'Misc',
        type    : 'group',
        icon    : 'heroicons_outline:menu',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
