import { FileParameter } from "app/app-clients/general-client";
import { AttachmentTypeConst } from "app/core/consts/attachment-type-const";
import { ImageCategoryConst } from "app/core/consts/image-category-const";
import { PhotoService } from "app/services/photo.service";

export class CkEditorUploadAdapter {
  private loader;
  constructor(loader: any, private _photoService: PhotoService) {
    this.loader = loader;
  }

  uploadFile(file) {
    const fileToUpload: FileParameter = {
      data: file,
      fileName: file.name,
    };

    return this._photoService.UploadSinglePhoto(
      fileToUpload,
      AttachmentTypeConst.Photo,
      ImageCategoryConst.Property
    );
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this.uploadFile(file).subscribe(
            (result: any) => {
              if(result.succeeded) {
                const { id, filePath, fileUrl } = result.objectReturn;
                resolve({ default: fileUrl });
              }
            },
            (error) => {
              reject(error);
            }
          );
        })
    );
  }
}
