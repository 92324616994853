import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileResponse } from 'app/app-clients/general-client';
import { BehaviorSubject, Observable, of } from 'rxjs';
import * as saveAs from "file-saver";
import { catchError } from 'rxjs/operators';

declare const Swal: any;

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private _pageTitle: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(private _httpClient: HttpClient) {}

  get pageTitle$(): Observable<string | null> {
    return this._pageTitle.asObservable();
  }

  public showErrorMessage(message, title) {
    Swal.fire({
      icon: 'error',
      title: title,
      text: message,
    });
  }

  public showInfoMessage(message, title) {
    Swal.fire({
      icon: 'info',
      title: title,
      text: message,
    });
  }

  public showSuccessMessage(message, title) {
    Swal.fire({
      icon: 'success',
      title: title,
      text: message,
    });
  }

  public showConfirmMessage() {
    return Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });
  }

  setHeaderAccessToken() {
    const header = new HttpHeaders({
        'authorization': 'bearer ' + localStorage.getItem('accessToken'),
        'accept': "application/json",
    });
    return header;
  }

  getPageTitle(pageTitle: string) {
    this._pageTitle.next(pageTitle);
  }

  downLoadFile(response: FileResponse, fileName: string) {
    saveAs(response.data, fileName);
  }

  convertToUSDPrice(currentCurrencyNotation: string): Observable<number> {
    if(currentCurrencyNotation == "USD"){
      return of(1);
    }
    return this._httpClient
      .get<number>("https://currency-exchange.p.rapidapi.com/exchange", {
        headers: {
          "X-RapidAPI-Key": "825559b860msh6ee3c9f9fad4855p15c90ejsnd265e56c10a1",
          "X-RapidAPI-Host": "currency-exchange.p.rapidapi.com",
        },
        params: {
          from: currentCurrencyNotation,
          to: "USD",
          q: "1.0",
        },
      })
  }
}
