import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GrexScrollbarModule } from '@grex/directives/scrollbar/public-api';
import { GrexHorizontalNavigationBasicItemComponent } from '@grex/components/navigation/horizontal/components/basic/basic.component';
import { GrexHorizontalNavigationBranchItemComponent } from '@grex/components/navigation/horizontal/components/branch/branch.component';
import { GrexHorizontalNavigationDividerItemComponent } from '@grex/components/navigation/horizontal/components/divider/divider.component';
import { GrexHorizontalNavigationSpacerItemComponent } from '@grex/components/navigation/horizontal/components/spacer/spacer.component';
import { GrexHorizontalNavigationComponent } from '@grex/components/navigation/horizontal/horizontal.component';
import { GrexVerticalNavigationAsideItemComponent } from '@grex/components/navigation/vertical/components/aside/aside.component';
import { GrexVerticalNavigationBasicItemComponent } from '@grex/components/navigation/vertical/components/basic/basic.component';
import { GrexVerticalNavigationCollapsableItemComponent } from '@grex/components/navigation/vertical/components/collapsable/collapsable.component';
import { GrexVerticalNavigationDividerItemComponent } from '@grex/components/navigation/vertical/components/divider/divider.component';
import { GrexVerticalNavigationGroupItemComponent } from '@grex/components/navigation/vertical/components/group/group.component';
import { GrexVerticalNavigationSpacerItemComponent } from '@grex/components/navigation/vertical/components/spacer/spacer.component';
import { GrexVerticalNavigationComponent } from '@grex/components/navigation/vertical/vertical.component';

@NgModule({
    declarations: [
        GrexHorizontalNavigationBasicItemComponent,
        GrexHorizontalNavigationBranchItemComponent,
        GrexHorizontalNavigationDividerItemComponent,
        GrexHorizontalNavigationSpacerItemComponent,
        GrexHorizontalNavigationComponent,
        GrexVerticalNavigationAsideItemComponent,
        GrexVerticalNavigationBasicItemComponent,
        GrexVerticalNavigationCollapsableItemComponent,
        GrexVerticalNavigationDividerItemComponent,
        GrexVerticalNavigationGroupItemComponent,
        GrexVerticalNavigationSpacerItemComponent,
        GrexVerticalNavigationComponent
    ],
    imports     : [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        GrexScrollbarModule
    ],
    exports     : [
        GrexHorizontalNavigationComponent,
        GrexVerticalNavigationComponent
    ]
})
export class GrexNavigationModule
{
}
