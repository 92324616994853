import { RoleTypes, IdentityResult } from './../../app-clients/general-client';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { ForgetPasswordModel, GeneralClient, LoginUserModel, ResetPasswordModel } from 'app/app-clients/general-client';

@Injectable()
export class AuthService
{
    private _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _generalClient: GeneralClient
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(forgetPassword: ForgetPasswordModel): Observable<any>
    {
        return this._generalClient.apiUserForgetPassword(forgetPassword);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(resetPassword: ResetPasswordModel): Observable<any>
    {
        return this._generalClient.apiUserResetPassword(resetPassword);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(request: LoginUserModel): Observable<IdentityResult>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('Email is already logged in.');
        }

        return this._generalClient.apiUserLogin(request).pipe(
            switchMap((response: any) => {
                if (response && response.succeeded) {
                    if (response.roles.includes(this.getRoleName(RoleTypes.SystemAdministrator))) {
                        // Store the access token in the local storage
                        this.accessToken = response.accessToken;
        
                        // Set the authenticated flag to true
                        this._authenticated = true;
        
                        // Store the user on the user service
                        this._userService.user = response.user;
        
                        // Return a new observable with the response
                        return of(response);
                    } 
                }
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string, email: string, password: string, company: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * role name
     *
     * 
     */
    getRoleName(role: RoleTypes): string {
        let roleName = "";
        if (role == RoleTypes.SystemAdministrator) {
            roleName = "SystemAdministrator";
        }
        else if (role == RoleTypes.InternalUser) {
            roleName = "InternalUser";
        }
        else if (role == RoleTypes.LocalService) {
            roleName = "LocalService";
        }
        else if (role == RoleTypes.Seller) {
            roleName = "Seller";
        }
        // else if (role == RoleTypes.Supplier) {
        //     roleName = "Supplier";
        // }

        return roleName;
    }
}
