import { NgModule } from '@angular/core';
import { GrexTailwindService } from '@grex/services/tailwind/tailwind.service';

@NgModule({
    providers: [
        GrexTailwindService
    ]
})
export class GrexTailwindConfigModule
{
    /**
     * Constructor
     */
    constructor(private _grexTailwindConfigService: GrexTailwindService)
    {
    }
}
