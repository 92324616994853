import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrexDrawerComponent } from '@grex/components/drawer/drawer.component';

@NgModule({
    declarations: [
        GrexDrawerComponent
    ],
    imports     : [
        CommonModule
    ],
    exports     : [
        GrexDrawerComponent
    ]
})
export class GrexDrawerModule
{
}
