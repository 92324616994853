import { NgModule } from '@angular/core';
import { GrexFindByKeyPipe } from '@grex/pipes/find-by-key/find-by-key.pipe';

@NgModule({
    declarations: [
        GrexFindByKeyPipe
    ],
    exports     : [
        GrexFindByKeyPipe
    ]
})
export class GrexFindByKeyPipeModule
{
}
