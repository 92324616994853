import { NgModule } from '@angular/core';
import { environment } from 'environments/environment';
import { API_BASE_URL as GENERAL_BASE_URL, GeneralClient } from './general-client';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    GeneralClient,

    { provide: GENERAL_BASE_URL, useValue: environment.baseUrl }
  ],
})
export class ApiClientModule { }
