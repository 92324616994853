import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import * as CustomEditor from "../custom-ckeditor/build/ckeditor";
import { ChangeEvent } from "@ckeditor/ckeditor5-angular";
import { CkEditorUploadAdapter } from "./config/CkEditorUploadAdapter";
import { PhotoService } from "app/services/photo.service";

@Component({
  selector: "app-ckeditor",
  templateUrl: "./ckeditor.component.html",
  styleUrls: ["./ckeditor.component.scss"],
})
export class CkeditorComponent implements OnInit {
  @Input() content: string;
  @Output() eventOnChange = new EventEmitter<any>(null);

  public Editor = CustomEditor;
  constructor(private _photoService: PhotoService) {}

  ngOnInit(): void {}

  config = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "underline",
        "|",
        "imageUpload",
        "mediaEmbed",
        "link",
        "|",
        "bulletedList",
        "numberedList",
        "alignment",
        "|",
        "insertTable",
        "indent",
        "outdent",
        "blockQuote",
        "undo",
        "redo",
        "exportWord",
        "fontSize",
        "fontFamily",
        "highlight",
        "fontColor",
        "horizontalLine",
        "specialCharacters",
        "todoList",
        "sourceEditing",
        "fontBackgroundColor",
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableCellProperties",
        "tableProperties",
        "imageTextAlternative",
        "imageStyle:inline",
        "imageStyle:block",
        "imageStyle:side",
        "linkImage",
      ],
    },
    language: "en",
    image: {
      toolbar: [
        "imageTextAlternative",
        "imageStyle:inline",
        "imageStyle:block",
        "imageStyle:side",
        "linkImage",
      ],
    },
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableCellProperties",
        "tableProperties",
      ],
    },
  };

  onChange({ editor }: ChangeEvent) {
    if (editor) {
      const data = editor.getData();
      this.eventOnChange.emit(data);
    }
  }

  onReady(eventData) {
    const photoService = this._photoService;
    eventData.plugins.get("FileRepository").createUploadAdapter = function (
      loader
    ) {
      return new CkEditorUploadAdapter(loader, photoService);
    };
  }
}
