import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { GrexScrollbarModule } from '@grex/directives/scrollbar';
import { GrexFindByKeyPipeModule } from '@grex/pipes/find-by-key';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ToastModule } from 'primeng/toast';
import { CkeditorComponent } from './ckeditor/ckeditor.component';
import { PositiveNumberDirective } from 'app/core/input-type/positiveNumberDirective';
import { TwoDigitDecimaDirective } from 'app/core/input-type/twoDigitDecimaDirective';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatNativeDateModule } from '@angular/material/core';
import { GrexCardModule } from '@grex/components/card';
import { GrexDrawerModule } from '@grex/components/drawer';
import { MaterialFileInputModule } from 'ngx-mat-file-input';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
    declarations: [
        TwoDigitDecimaDirective,
        PositiveNumberDirective,
        CkeditorComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatStepperModule,
        GrexFindByKeyPipeModule,
        GrexScrollbarModule,
        ConfirmDialogModule,
        MessageModule,
        MessagesModule,
        CKEditorModule,
        ToastModule,
        MatButtonModule,
        MatIconModule,
        MatProgressBarModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatPaginatorModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatNativeDateModule,
        GrexCardModule,
        GrexDrawerModule,
        MaterialFileInputModule,
        MatExpansionModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatStepperModule,
        GrexFindByKeyPipeModule,
        GrexScrollbarModule,
        ConfirmDialogModule,
        MessageModule,
        MessagesModule,
        CkeditorComponent,
        ToastModule,
        MatButtonModule,
        MatIconModule,
        MatProgressBarModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatPaginatorModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatNativeDateModule,
        GrexCardModule,
        GrexDrawerModule,
        MaterialFileInputModule,
        MatExpansionModule,
        TwoDigitDecimaDirective,
        PositiveNumberDirective,
    ]
})
export class SharedModule
{
}
