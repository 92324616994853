import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [

    // Redirect empty path to '/home'
    {path: '', pathMatch : 'full', redirectTo: 'property/property'},

    // Redirect signed in user to the '/home'
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'property/property'},

    // Auth routes (guest)
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
        ]
    },

    // Auth routes (logged in)
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            // {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            // Pages
            {path: '', children: [
                // Property
                {path: 'property', children: [
                    {path: 'property', loadChildren: () => import('app/modules/pages/property/property/property.module').then(m => m.PropertyModule)},
                    // {path: 'commercial', loadChildren: () => import('app/modules/pages/property/commercial/commercial.module').then(m => m.CommercialModule)}
                ]},

                // Project
                {path: 'project', loadChildren: () => import('app/modules/pages/project/project.module').then(m => m.ProjectModule)},

                // Contact
                {path: 'contact', loadChildren: () => import('app/modules/pages/contact/contact.module').then(m => m.ContactModule)},

                // Time For Post
                {path: 'time-for-post', loadChildren: () => import('app/modules/pages/time-for-post/time-for-post.module').then(m => m.TimeForPostModule)},

                // User
                {path: 'user', children: [
                    {path: 'seller', loadChildren: () => import('app/modules/pages/seller/seller.module').then(m => m.SellerModule)},
                    {path: 'guest', loadChildren: () => import('app/modules/pages/supplier/supplier.module').then(m => m.SupplierModule)},
                ]},

                // Social Network
                {path: 'social-network', loadChildren: () => import('app/modules/pages/social-network/social-network.module').then(m => m.SocialNetworkModule)},

                // Banner
                {path: 'banner', loadChildren: () => import('app/modules/pages/banner/banner.module').then(m => m.BannerModule)},
                // Common Config
                {path: 'common-config', loadChildren: () => import('app/modules/pages/common-config/common-config.module').then(m => m.CommonConfigModule)},
                // News
                {path: 'news', children: [
                    {path: 'news-category', loadChildren: () => import('app/modules/pages/news-category/news-category.module').then(m => m.NewsCategoryModule)},
                    {path: 'news', loadChildren: () => import('app/modules/pages/news/news.module').then(m => m.NewsModule)}
                ]},
                // Promotion
                {path: 'promotion', children: [
                    {path: 'notification', loadChildren: () => import('app/modules/pages/notification/notification.module').then(m => m.NotificationModule)}
                ]}
            ]},

            // 404 & Catch all
            {path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/pages/errors/error-404/error-404.module').then(m => m.Error404Module)},
            {path: '**', redirectTo: '404-not-found'}
        ]
    }
];
