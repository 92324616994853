import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { GrexMediaWatcherModule } from '@grex/services/media-watcher/media-watcher.module';
import { GrexSplashScreenModule } from '@grex/services/splash-screen/splash-screen.module';
import { GrexTailwindConfigModule } from '@grex/services/tailwind/tailwind.module';
import { GrexUtilsModule } from '@grex/services/utils/utils.module';

@NgModule({
    imports  : [
        GrexMediaWatcherModule,
        GrexSplashScreenModule,
        GrexTailwindConfigModule,
        GrexUtilsModule
    ],
    providers: [
        {
            // Use the 'fill' appearance on Angular Material form fields by default
            provide : MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill'
            }
        }
    ]
})
export class GrexModule
{
    /**
     * Constructor
     */
    constructor(@Optional() @SkipSelf() parentModule?: GrexModule)
    {
        if ( parentModule )
        {
            throw new Error('GrexModule has already been loaded. Import this module in the AppModule only!');
        }
    }
}
